import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './Home';
import LegionsOfHeimland from './Legionsofheimland';
import LegionsOfHeimlandko from './Legionsofheimlandko';
import Navbar from './Navbar'; // Navbar 컴포넌트 불러오기

const App = () => {
  return (
    <Router>
      <div>
        {/* Navbar를 모든 페이지에 공통으로 추가 */}
        <Navbar />
        
        {/* Routes 설정 */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legionsofheimland" element={<LegionsOfHeimland />} />
          <Route path="/legionsofheimlandko" element={<LegionsOfHeimlandko />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
